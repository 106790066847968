import { Link, graphql } from 'gatsby';
import React from 'react';

import { ReactComponent as Logo } from '../../assets/design/logo-alt.svg';
import keepsakeHero from "../../assets/images/fingerprint-keepsakes/fingerprint-hero.jpeg"
import fingerprintStone1 from "../../assets/images/fingerprint-keepsakes/fingerprint-stone-1.jpg"
import fingerprintStone2 from "../../assets/images/fingerprint-keepsakes/fingerprint-stone-2.jpg"
import fingerprintMug1 from "../../assets/images/fingerprint-keepsakes/fingerprint-mug-1.jpg"
import fingerprintMug2 from "../../assets/images/fingerprint-keepsakes/fingerprint-mug-2.jpg"
import Guarantee from '../../components/guarantee';
import Layout from '../../components/layout';
import SEO from '../../components/seo';
import Services from '../../components/services';
import StyledButton from '../../components/styledButton';

const images = [
    fingerprintStone1,
    fingerprintStone2,
    fingerprintMug1,
    fingerprintMug2
];


const FingerprintKeepsake = ({ data }) => {
  const [
    {
      node: {
        frontmatter: { title, price },
        html,
      },
    },
  ] = data.allMarkdownRemark.edges;
  return (
    <Layout
      headerClass='relative bg-white'
      bodyClass='px-0 md:px-0 lg:px-0'
      hasHero={false}
      heroImage={keepsakeHero}
    >
      <SEO
        title={`Affordable ${title}`}
        description={`City of Oaks Funeral Home and Cremation provides affordable glass orb cremation keepsakes starting at ${price}`}
      />
      <div className='flex flex-col mt-6'>
        <div className='w-24 h-24 self-center'>
          <Logo />
        </div>
        <div className='text-center'>
          <h2 className='text-xl md:text-2xl uppercase text-gray-700'>
            City of Oaks Funeral Home & Cremations
          </h2>
        </div>
      </div>

      <div className='container mx-auto px-6 md:px-10 lg:px-24 pt-8 '>
        <div className='flex flex-col'>
          <div className='markdown-body py-6 text-lg'>
            <h1 className='text-gray-700 text-sm'>
              City of Oaks Funeral Home and Cremation offers handmade fingerprint keepsakes.
            </h1>
            <div
              className='mb-6'
              dangerouslySetInnerHTML={{ __html: html }}
            />
            <div className='flex items-center justify-center h-100'>
              {images.map((image, index) => (
                  <div className='h-100 pl-2 pr-2' key={index}>
                <img
                  key={index}
                  src={image}
                  alt="Fingerprint keepsake"
                />
              </div>
              ))}
          </div>
          <p className='text-xl pb-8'>
            We assist families all throughout North Carolina and we do{' '}
            <strong>not</strong> charge an additional mileage fee.
          </p>
          <div className='self-center mb-10'>
            <Link to='/contact/'>
              <StyledButton>Find out more</StyledButton>
            </Link>
          </div>
        </div>
      </div>
      </div>
      <Services />
      <Guarantee />
    </Layout>
  );
};

export const query = graphql`
  query FingerprintKeepsake {
    allMarkdownRemark(
      filter: {
        fileAbsolutePath: { regex: "/content/pages/fingerprint-keepsake/" }
      }
    ) {
      edges {
        node {
          id
          frontmatter {
            title
            slug
            price
            blurb
          }
          html
        }
      }
    }
  }
`;
export default FingerprintKeepsake;
